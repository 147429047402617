import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

// 获取人工审核列表
export const GetReviewWayList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/ManualList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 获取人工审核详情
export const GetReviewWayDetails = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/ManualDetailList`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 人工审核小红点
export const ManualExist = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/ManualExist`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 审核运单
export const UpdateReviewWay = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/ManualDeal`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 提交自动审核
export const SubmitAutoManual = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/SubmitAutoManual`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 全部驳回
export const RejecttAutoManual = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/WayBillManual/RejecttAutoManual`,params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}